body {
  font-size: $font-size-body;
  font-family: $font-family-body;
  // background-color: $color-page-background;
  letter-spacing: 0 !important;
  color: $color-body;

  &.common-signup-page {
    background: url("../../images/banner-bg-1920.png");
    @media (width < 1600px) {
        background: url("../../images/banner-bg-1596.png");
      }
      @media (width < 1230px) {
        background: url("../../images/banner-bg-1224.png");
      }
      @media (width < 991px) {
        background: url("../../images/banner-bg-845.png");
      }
    @media (width < 360px) {
      background: url("../../images/banner-bg-360.png");
    }


    

     
    

    // &.login-page {
    //   background-image: none;
    // }
  }
}

.container {
  @media (width < 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.common-signup-page .main-content-sec {
  margin-top: 0;
  padding-bottom: 0;
}

.error {
  color: $errorColor;
  font-size: $font-size-xsml;
  display: block;
}

.main-content-sec {
  margin-top: 120px;
  min-height: calc(100vh - 120px);
  padding-bottom: 120px;

  .no-spacing & {
    margin-top: 0;
  }
}

.container-fluid {
  max-width: 1920px;
}

.w-100 {
  width: $width-full;
}

ol,
ul {
  padding-left: 17px;
}


/* fallback */
@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 100 200 300;
  src: url(https://fonts.gstatic.com/s/materialsymbolsrounded/v190/sykO-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvLgyidOK7BDB_Qb9vUdV6_gjDK-P3JuEXX.woff2) format('woff2');
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24
}

.material-symbols-fw-200 {
  font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 24
}

.material-symbols-fw-200-filled {
  font-variation-settings:
    'FILL' 1,
    'wght' 200,
    'GRAD' 0,
    'opsz' 24
}

.icon-filled {
  font-variation-settings:
    'FILL' 1,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24
}



.common-bg {
  min-height: inherit;
  height: 100vh;
  // background: url("../../images/banner-bg.png");
  position: relative;
  overflow: hidden;

  @include breakpoint(small) {
    min-height: 95vh;
    height: inherit;
  }

  &:after {
    content: "";
    position: absolute;
    background: url("../../images/homepage-banner-outline-icon.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60% auto;
    z-index: -1;
    top: 0;
    right: -12%;
  }
}

.company-info-logo {
  min-height: inherit;
  height: 20vh;
  // background: url("../../images/banner-bg.png");
  position: relative;
  overflow: hidden;

  @include breakpoint(small) {
    min-height: 30vh;
    height: inherit;
  }

  &:after {
    content: "";
    position: absolute;
    background: url("../../images/homepage-banner-outline-icon.png");
    height: 20%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60% auto;
    z-index: -1;
    top: 0;
    right: -12%;
  }
}

.container {
  @include breakpoint(xsml) {
    max-width: 100%;
  }

  @include breakpoint(large) {
    max-width: $container;
  }

  @include breakpoint(xlarge) {
    max-width: $container-large;
  }


}

.social-icon {
  svg {
    width: 14px;
  }
}

.MuiButtonBase-root,
.MuiButton-root,
.MuiMenuItem-root,
.MuiFormLabel-root,
.MuiTypography-root,
.MuiFormControl-root,
.MuiInputBase-root,
.MuiAutocomplete-root {
  font-family: $font-family-body !important;
  letter-spacing: 0 !important;
}

.fs-20 {
  font-size: $font-size--1_25rem !important;
}

.fs-18 {
  font-size: $font-size-large !important;
}

.fs-16 {
  font-size: $font-size-body !important;
}

.fs-14 {
  font-size: $font-size-sml !important;
}

.fs-13 {
  font-size: .8125rem !important;
}
.fs-12 {
  font-size: $font-size-xsml !important;
}

.editor_components .ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.terms {
  ol li {
    padding-bottom: 5px;
    font-size: 14px;
  }
}

.input-w-btn {
  border: 1px solid #000;
  border-radius: 8px;
}

.input-w-btn>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none !important;

  border-left: 1px solid $color-body !important;
  color: $color-body !important;
  min-width: 150px !important;
}

.input-w-btn .MuiOutlinedInput-notchedOutline {
  border: none;
}

.input-w-btn {
  &.fixed-right {
    position: relative;
    border: none;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $border-color-card;
      padding-right: 150px;
    }

    .MuiInputBase-input {
      padding-right: 160px;
    }

    .btn {
      position: absolute;
      right: 0;
      background-color: $color-primary;
      color: $color-white !important;
      height: 100%;
      border-color: $border-color-card !important;
    }

  }
}



.MuiTabs-root {
  .MuiTabs-flexContainer {
    .MuiTab-root {
      // color: $color-black;
      font-family: $font-family-body;
      text-transform: none;
      font-weight: normal;

      &.Mui-selected {
        // color: $color-primary;

        // svg path {
        //   fill: #00aae5;
        // }
      }
    }
  }

  // .MuiTabs-indicator {
  //   background-color: $color-primary;
  // }

  .MuiTabs-scrollButtons {
    &.Mui-disabled {
      opacity: 0.3;
    }
  }
}

.icon-instagram {
  #instagram {
    width: 100%;
    height: 100%;
    // vertical-align: top;
  }
}

.icon-youtube {
  color: #f00;
}

.text-link {
  color: $color-body;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.MuiFormHelperText-root {
  font-family: $font-family-body !important;
  letter-spacing: normal !important;
}

.color-blk-5d {
  color: $color-black-5d !important
}

svg {
  &[data-testid="YouTubeIcon"] {
    color: $color-red;
  }
}



.MuiTooltip-popper {
  z-index: 9999999 !important;
}

.drawer-plan-detail {
  .MuiDrawer-paper {
    max-width: 430px;
  }
}

.app-loader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  z-index: 99;

  >div {
    width: 9.375rem !important;
    height: 9.375rem !important;
  }
}

.bg-f2f2f2 {
  background-color: #f2f2f2 !important;
}

.bdr-rad-14 {
  border-radius: .875rem !important;
}

.proposal-negotiation-detail.inner-content {
  display: flex;
  gap: 1rem;
}

.color-c5cddc {
  color: $color-c5cddc
}

.upgrade-premium-list {
  li {
    padding-bottom: 5px;
  }
}

.MuiAutocomplete-option .MuiCheckbox-root {
  padding: 0;
}

.MuiAutocomplete-option {
  font-size: 14px;
  font-family: $font-family-body;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-btm-spc-exact .main-content-sec {
  padding-bottom: 20px;
}

.dropdown-small .MuiSelect-select {
  padding:3px 14px;
  font-size: 14px;
}

.MuiPopover-root .small-dropdown-menu .MuiMenuItem-root {
  font-size: 14px;
}
.small-dropdown-menu .MuiCheckbox-root {
  padding: 0;
}

.common-select-dropdown .MuiSelect-multiple span.MuiCheckbox-root {
  display: none;
}
.MuiAutocomplete-root .MuiFormLabel-root,
.MuiAutocomplete-root .MuiInputBase-root .MuiAutocomplete-inputFocused {
  font-size: 14px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  height: auto;
}

.common-switch {
  .MuiSwitch-thumb {
    background-color: #fff;
    width: 30px;

    @media (width < 768px) {
      width: 26px;
    }

    height: 22px;
    margin: 0;
    border-radius: 28px;
  }

  .MuiSwitch-root {
    @media (width > 767px) {
      width: 80px;
    }

    width: 70px;
    height: 40px;
    font-size: 14px;
    padding: 6px;

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(32px);

      @media (width < 768px) {
        transform: translateX(26px);
      }

      +.MuiSwitch-track {
        background-color: #000;
        border: 1px solid #000;
        opacity: 1;
        border-radius: 20px;
      }
    }
  }

  .MuiSwitch-track {
    opacity: 1;

    &:before,
    &:after {
      @media (width < 768px) {
        top: 19px !important;
      }

    }
  }
}
._influencer_dob_slash {
  padding: 6px 4px;
}

.ff-body {
  font-family: $font-family-body !important;
}

.list-style-none {
  list-style: none !important;
}

.deactivate-popup-container .popup_content_container {
  padding-bottom: 0;
}

.form-client-registration {
  margin: 0 auto 60px;
  max-width: 600px
}

#rec-type-label {
  background: #fff;
  padding-left: 5px;
}
#company_address {
  padding-top: 0;
}