.security-item {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
    gap: 20px; /* Adjust spacing between text and button */
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
  }
  
  .security-label {
    min-width: 200px; /* Ensures all labels have the same width */
    text-align: left;
    flex-shrink: 0; /* Prevents label from shrinking */
  }
  
  .security-btn-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between button & timer */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 468px) {
    .security-item {
      flex-direction: column; /* Stack text and button */
      align-items: flex-start; /* Align items properly */
      gap: 5px; /* Reduce spacing */
    }
  
    .security-label {
      min-width: auto; /* Allow text to wrap naturally */
      width: 100%;
    }
  
    .security-btn-container {
      width: 100%;
    }
  
    .security-btn-container .btn {
      width: 100%; /* Make buttons full-width on smaller screens */
    }
  }
  
  // Deactivate/Delete Text
  .deactivate-text {
    font-size: 16px;
    color: #d9534f; // Slightly red to indicate caution
    font-weight: 500;
  }
  
  // Timer Text (When button is disabled)
  .timer-text {
    font-size: 14px;
    color: red;
    font-weight: 500;
  }

  .deactivate-popup-input-sec {
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }
  

  #remarkDescription {
    padding-top: 0;
  }
  
 