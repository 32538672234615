._influencer_social_connect_main_container {
  .select-account-image {
    max-width: 70px;
    border-radius: 50%;
  }

  ._influencer_social_connect_facebook_icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .faceBookButton{
      display:none
    }


    .card-wrapper {
      width: 100%;
    }

    .connected-facebook-account-details {
      display: flex;

      img {
        max-width: 70px;
        border-radius: 50%;
      }


    }




    .influencer_facebook {
      color: blue;
    }
  }

  .availaile-account-list {
    li {
      display: flex;
      align-items: center;

      .select-account-image {
        max-width: 70px;
        border-radius: 50%;
      }

      p {
        padding: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }


  }

  .google-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .google-accounts {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          margin-right: 10px;
        }

      }
    }

    .google-container-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.select-account-dialog {
  width: 550px !important;

  .select-account-image {
    max-width: 70px;
    border-radius: 50%;
  }
}

.apply-connected-account-buttons {
  margin-top: 10px;

  button {
    padding: 5px 14px !important;
  }
}

._influencer_social_connect-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 0;
  margin-right: 10px;
  margin-bottom: 5px;
}

#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #F00;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0F0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00F;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4D658D;
  border-bottom-color: #4D658D;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #D4CC6A;
  border-bottom-color: #D4CC6A;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417C;
  border-bottom-color: #84417C;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.select-account-dialog {
  margin: 0 auto;
}

.guide-to-connect {
  li {
  padding-bottom: 3px;
  font-size: 14px;
    
  }
  p {
    font-size: 14px;
  }
    strong {
      font-size: 16px;
      line-height: 24px;
    }
}

